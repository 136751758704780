import React from "react";
import Container from '../components/styled/Container'
import styled from 'styled-components'
import {primaryTitleFont} from '../styles/fonts'
import breakpoints from '../styles/breakpoints'
import {animateChildren, inAndUp, fadeIn, extraLong} from '../styles/animation'
import {motion} from 'framer-motion'
import SelectedPhotographs from "../components/SelectedPhotographs";
import ParallaxPageTitle from '../components/motion/ParallaxPageTitle'

const RegularPage = () => (
  <Container>
    <ParallaxPageTitle>
      <span>
        Selected<br/>
        Photographs
      </span>
    </ParallaxPageTitle>
    <SelectedPhotographs />
  </Container>
);

export default RegularPage;
